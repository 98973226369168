<div id ="container">
  <!-- <div id="container-for-icons"> -->
      <!-- <div id = "background-left">  -->
      <div id= "polygon1">
        <img src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/Polygon.png?alt=media&token=e4313a32-a7ce-4a69-b670-3f5926a5055d">
      </div>

      <div id = "bigCircle1"></div>
      <div id= "bigCircle2"></div>
      <div id= "smallCircle1"></div>


      <div id= "polygon2">
        <img src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/Polygon2.png?alt=media&token=a437f2c7-20fb-4add-8232-4c72bfe9c700">
      </div>
      <div id= "smallCircle2"></div>
      <div id= "smallCircle3"></div>
  <!-- </div> -->

  <div id="container-real">
    <div id = "header">
      <img class ="tape"
      src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/invalid-name.png?alt=media&token=c9393c3f-ec7f-4ac6-93be-04eda262ccd6"
      srcset = "https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/invalid-name%402x.png?alt=media&token=6707afc3-1879-469b-9af5-b1e88a08ad29 2x,    https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/invalid-name%403x.png?alt=media&token=1201a758-0052-4ec2-ab35-40a81e8bab4a 3x">
      <img class = "title" src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/KakaoTalk_Photo_2020-02-09-16-27-24.png?alt=media&token=9687c0f8-9e53-4caa-be64-014171e0ffa3" >
    </div>
    <div id= "content">
        <div class= "description normal part1">
          지친 당신의 편안한 주말을 함께할 <br>
          비디오 쟈-키 입니다. <br>
          매주 영화와 드라마를 추천해드려요! <br>
        </div>

      <div class= "description part2 bold">
        금요일 저녁 7시
      </div>

      <div class= "description body part3">
        비디오쟈-키의 한땀 한땀 콘텐츠 큐레이션이 <br>
        당신의 메일함으로 찾아 갑니다.
      </div>

      </div>


    <div id="subscription">
      <!-- 요 밑은 기존 wix 코드임. 일단은 손 안대고 다음에 수정하는 것이 목표 -->
          <link href="https://fonts.googleapis.com/css?family=Karla&display=swap" rel="stylesheet">
      <style>

      </style>
      <div id="stb_subscribe" *ngIf = "(submitted == false)||((clicked==true)&&(errorStatus==true))">

          <form action="https://stibee.com/api/v1.0/lists/tsnBhVYz3gO7bWI6sWPFYsGSVD6R/public/subscribers" method="POST" target="_blank" accept-charset="utf-8" class="stb_form" name="stb_subscribe_form" id="stb_subscribe_form" novalidate="">
              <fieldset class="stb_form_set">
                  <label for="stb_email" class="stb_form_set_label">메일 주소</label>
                  <input type="email" [(ngModel)] = "emailAddress" class="stb_form_set_input" id="stb_email" name="email" >
                  <div *ngIf="errorStatus" class="stb_form_msg_error" id="stb_email_error">{{errorMsg}}</div>
              </fieldset>
              <form [formGroup]= "privacyForm">
                <div class = "form-group form-check">
                  <input type="checkbox" formControlName="acceptPrivacyTerms" id="acceptPrivacyTerms" class="form-check-input" [ngClass]="{ 'is-invalid': clicked && privacyFormData.acceptPrivacyTerms.errors }" />
                  <label for="acceptPrivacyTerms" class="form-check-label" style ="margin-left:30px"><strong (click)= openPopUp(true)>개인정보 수집 및 이용</strong> 동의</label>
                  <div *ngIf="clicked && privacyFormData.acceptPrivacyTerms.errors" class="invalid-feedback">개인 정보 약관 동의가 필요해요.</div>
                </div>
              </form>
              <form [formGroup]= "marketingForm" style ="padding-top:10px; ">
                <div class = "form-group form-check">
                  <input type="checkbox" formControlName="acceptMarketingTerms" id="acceptMarketingTerms" class="form-check-input"  [ngClass]="{ 'is-invalid': clicked && marketingFormData.acceptMarketingTerms.errors }" />
                  <label for="acceptTerms" class="form-check-label" style ="margin-left:30px; "><strong (click)= openPopUp(false)>광고성 정보 수신</strong> 동의</label>
                </div>
              </form>

              <fieldset class="stb_form_set_submit">
                  <button (click)= "onClickSubscribe()" type="submit" class="stb_form_submit_button" id="stb_form_submit_button"  style="background-color: rgb(55, 29, 180); color: rgb(255, 255, 255)"
  >구독하기</button>
              </fieldset>
          </form>
          <div class="stb_form_result" id="stb_form_result">
          </div>
      </div>
      <div id ="stb_form_done" *ngIf= "(errorStatus == false) && (clicked == true) &&(submitted == true)">
        <p>구독 완료</p>
        <p>메일함에서 <strong>웰컴 메일</strong>을 확인해주세요!</p>
      </div>

    </div>

  </div>


  </div>


<!-- </div> -->
