export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBmxgilklgQ0rXghyVUfpqeEDBnZeir3I0",
    authDomain: "videojockey-883a1.firebaseapp.com",
    databaseURL: "https://videojockey-883a1.firebaseio.com",
    projectId: "videojockey-883a1",
    storageBucket: "videojockey-883a1.appspot.com",
    messagingSenderId: "977670412964",
    appId: "1:977670412964:web:758ee895a0b4488acaa431",
    measurementId: "G-ET029VLZR0"
  }
};
