import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { SubscriberLog } from './subscriber';

@Injectable()
export class SubscribeLogService {
  constructor(private firestore:AngularFirestore){ }
  createLog(log: SubscriberLog){
    return this.firestore.collection("logs").add(log);
  }
}
