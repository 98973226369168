<div class="modal-header">
      <h4 class="modal-title" *ngIf="showPrivacy==true">{{privacyTermTitle}}</h4>
      <h4 class="modal-title" *ngIf="showPrivacy==false">{{marketingTermTitle}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
       <div  *ngIf="showPrivacy==true">
          {{privacyTermContent}}
       </div>
       <div  *ngIf="showPrivacy==false">
          {{marketingTermContent}}
       </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
