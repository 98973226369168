import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-dialog-term',
  templateUrl: './dialog-term.component.html',
  styleUrls: ['./dialog-term.component.css']
})
export class DialogTermComponent implements OnInit {
  // showPrivacy : true은 정보 제공 동의
  //  false는 광고성 정보 제공 동의
  @Input() showPrivacy;
  privacyTermTitle = "개인정보 수집"
  privacyTermContent = "뉴스레터 발송을 위한 최소한의 개인정보를 수집하고 이용합니다. \n 수집된 정보는 발송 외 다른 목적으로 이용되지 않으며, 서비스가 종료되거나 구독을 해지할 경우 즉시 파기됩니다."
  marketingTermTitle = "광고성 정보 수신"
  marketingTermContent = "제휴 콘텐츠, 프로모션, 이벤트 정보 등의 광고성 정보를 수신합니다."

constructor(public activeModal: NgbActiveModal) {}
  ngOnInit(): void {
  }
}
