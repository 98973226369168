import { Component, OnInit } from '@angular/core';
// TODO : 서비스 네이밍이 못생김 ㅜㅜ 바꾸자
import {SubscribeServiceService} from '../subscribe-service.service';
import {HttpClient} from '@angular/common/http';
import {SubscribeResponse} from '../subscribe-response';
import {DomParser} from 'dom-parser';
import {SubscribeLogService} from '../subscribe-log.service';
import {SubscriberLog} from '../subscriber';
import {GoogleAnalyticsService} from '../google-analytics.service';
// MARK :  - 개인정보동의관련
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';


import {DialogTermComponent} from '../dialog-term/dialog-term.component';


@Component({
  selector: 'app-main-part',
  templateUrl: './main-part.component.html',
  styleUrls: ['./main-part.component.css']
})

export class MainPartComponent implements OnInit {
  res = ""
  errorMsg = ""
  errorStatus = false
  clicked = false
  submitted = false
  successMsg = "구독완료! 메일함에서 웰컴 메일을 확인해 주세요! "
  duplicatedMsg = "이미 구독 중인 이메일 주소입니다."
  privacyTermAgree = false
  marketingTermAgree = false
  emailAddress = ""
  result : string;
  // For Log Only (for now)
  status : string;
  messages = {
    "duplicated" : "이미 구독 중인 이메일 주소입니다.",
    "unknown" : "잠시 후 다시 이용해주세요.",
    "wrong" : "이메일 주소를 다시 확인해주세요."
  }
  privacyForm:FormGroup;
  marketingForm:FormGroup;

  constructor(
    private subscribeService: SubscribeServiceService,
    private logService :  SubscribeLogService,
    private googleAnalyticsService : GoogleAnalyticsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) { }
  // 주는 답변으로 확인한다.

  ngOnInit() {
    this.privacyForm = this.formBuilder.group({
      acceptPrivacyTerms : [false, Validators.requiredTrue]
    })
    this.marketingForm = this.formBuilder.group({
      acceptMarketingTerms : [false, Validators.requiredTrue]
    })
  }
  get marketingFormData(){return this.marketingForm.controls;}
  get privacyFormData(){return this.privacyForm.controls;}
  // const body = { email: "tester@test.com"}
  openPopUp(privacy:Boolean){
    const modalRef = this.modalService.open(DialogTermComponent);
    modalRef.componentInstance.showPrivacy = privacy;
  }
  onClickSubscribe(){
    this.clicked = true

    if(this.privacyForm.invalid){
      return;
    }else{
      this.privacyTermAgree = true
    }
    if(!this.marketingForm.invalid){
      this.marketingTermAgree = true
    }

    const validationData = this.subscribeService.validateEmail(this.emailAddress)
    if (validationData["validated"]){
      this.subscribeService.requestNewSubscriber(this.emailAddress, this.privacyTermAgree, this.marketingTermAgree).subscribe(
        data => {
          // if (data.Value.failDeny.length > 0){
          //   this.errorStatus = true
          //   this.errorMsg = this.messages["unknown"]
          //   this.status = "failDenyServer"
          // }
          if (data.Value.failExistEmail.length > 0){
            console.log("duplicated")
            this.errorStatus = true
            this.errorMsg = this.messages["duplicated"]
            this.status = "duplicated"
          }
          if (data.Value.failUnknown.length > 0){
            this.errorStatus = true
            this.errorMsg = this.messages["unknown"]
            this.status = "failUnknownServer"
          }
          if (data.Value.failWrongEmail.length > 0){
            this.errorStatus = true
            this.errorMsg = this.messages["wrong"]
            this.status = "failWrongEmailServer"
          }
          if (data.Value.success.length > 0){
            this.errorStatus = false
            this.clicked = true
            this.status = "success"
            this.submitted = true
          }

          var log : SubscriberLog = {
            email : this.emailAddress,
            status : this.status,
            datetime : new Date()
          };
          // this.logService.createLog(log);
          this.googleAnalyticsService.eventEmitter("subscribe", "subscribe", "click", this.status, 1)
          // var msgAndStatus = this.subscribeService.getMsgAndStatus(data)
          // this.errorStatus = msgAndStatus["isError"]
          // this.errorMsg = msgAndStatus["msg"]
          // this.clicked = true
          // console.log(this.errorStatus)
          // console.log(this.errorMsg)
          // console.log(this.clicked)
        }
      )
    }else{
      // 잘못된 메일 정보 일때,
      console.log("Invalid")

      this.errorStatus = true
      this.errorMsg = validationData["message"]
      this.status = "failWrongEmailWebpage"
      var log : SubscriberLog = {
        email : this.emailAddress,
        status : this.status,
        datetime : new Date()
      };
      this.logService.createLog(log);
      this.googleAnalyticsService.eventEmitter("subscribe", "subscribe", "click", this.status, 1)
    }
  }
}
