<div id= "container">
  <div id = "header">
    <!-- <div class= "go-back">버튼</div> -->
    <a routerLink = "/">
      <img class= "go-back"src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/KakaoTalk_Photo_2020-02-09-17-21-00.png?alt=media&token=4f728fe0-ba37-4b83-959c-0c531169c871">
    </a>
    <div class= "title-section">
      <div class= "title body">지난 레터 읽기</div>
      <div class = "subtitle body">Newsletter archive</div>
      <div class = "opMessage body">
        <div class= "message1 body">최신 비디오는 발송 3주 후에 들어와요.</div>
        <div class= "message2 body"></div>
      </div>
    </div>
  </div>
  <div id = "content">
    <div id = "box-container">
      <div *ngFor="let mail of mails | paginate: config" class= "box">
        <a href={{mail.url}}>
          <div class= "mail-title pc" *ngIf = "mail.id < 1000">
          Vol {{mail.id}}. {{mail.mailTitle}} <{{mail.filmTitle}}>
          </div>
          <div class= "mail-title pc" *ngIf = "mail.id > 1000">
          {{mail.mailTitle}}
          </div>
          <div class= "mail-title mobile" *ngIf = "mail.id < 1000">
            <div class = "text-box">
              <p classs="first-line">Vol {{mail.id}}. {{(mail.mailTitle.length > 22)?(mail.mailTitle|slice:0:22)+'...':(mail.mailTitle)}}</p>
              <p *ngIf = "mail.filmTitle"><{{mail.filmTitle}}></p>
                          </div>
          </div>
          <div class= "mail-title special mobile" *ngIf = "mail.id > 1000">
          <p>{{mail.mailTitle}}</p>
          </div>
        </a>
      </div>
    </div>
    <div id = "navigation">
         <pagination-template #p="paginationApi" [maxSize] ="maxSize" [id]="config.id" (pageChange)="config.currentPage = $event">
    <div class="custom-pagination">
      <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
        <span *ngIf="!p.isFirstPage()" (click)="p.previous()">
          <
            </span>
        </div>
        <div class="page-number" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
            <div *ngIf="p.getCurrent() === page.value">
              <div id= "triangle"></div>
              <span>{{ page.label }}</span>
            </div>
        </div>
        <div class="pagination-next" [class.disabled]="p.isLastPage()">
          <span *ngIf="!p.isLastPage()" (click)="p.next()"> > </span>
        </div>
    </div>

  </pagination-template>
    </div>
    <div class= "triangle"></div>
  </div>
</div>
