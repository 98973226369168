<div id ="container">
  <div id = "content">
    <div id= "header">
      <div class= "title">
      글 쓰는 사람들
    </div>
    <div class ="subtitle">
      Editors
    </div>
    </div>
    <div id="body">
      <!-- 여기는 리니어 -->
      <div class= "person">
        <img class = "icon" src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/054-wizard%403x.png?alt=media&token=cfcbbda4-0d6f-4bfb-b068-818e9f28d599">
        <div class= "name bold">리니어</div>
        <div class="introduction body">
          워너비 미니멀리스트 <br>
          AI 에게 말을 가르치는 일을 하고 있음 <br>
          백수 때 심심해서 넷플릭스만 보다가 비쟈를 시작하게 됨
        </div>
      </div>
      <!-- 여기는 욜로 -->
      <div class= "person">
        <img class ="icon" src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/035-leprechaun%403x.png?alt=media&token=e33d3909-3698-4c7a-a495-fa0edd6567e3">
        <div class= "name bold">욜로</div>
        <div class="introduction body">
          잡일 벌이기 전문가 <br>
          글쓰기와는 정 반대의 커리어를 걷는 중 <br>
          숨 쉬듯이 영화만 보는 시간을 재밌게 보내고 싶어 시작함 </div>
      </div>
      <!-- 여기는 서퍼 -->
      <div class= "person">
        <img class ="icon" src="https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/016-elf%403x.png?alt=media&token=2e2b6fb0-6f5d-4ce7-82ba-cdd1ae860b46">
        <div class= "name bold">서퍼</div>
        <div class="introduction body">
          한량 연습생 <br>
          돈 많은 백수의 꿈을 품고 성실히 출근 중 <br>
          개발 공부 하다가 뭔가 재밌어 보여서 비쟈를 같이 하게 됨
        </div>
      </div>
    </div>
  </div>
</div>
